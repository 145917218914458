import React, { useState } from 'react';
import { ApiRepos } from '../../../contracts/ContractVars';

const ForgotPassword = ({ onBackToLogin }) => {
    const [resetEmail, setResetEmail] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const validateEmail = (email) => {
        return email?.trim()
            && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email);
    };

    const handleResetPassword = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError('');
        setSuccess('');
        
        try {

            if (!validateEmail(resetEmail)) {
                setError('Please enter a valid email address');
                setIsLoading(false);
                return;
            }


            const response = await fetch(`${ApiRepos}account/forgot-password`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ EmailAddress: resetEmail })
            });

            const data = await response.json();
            // Handle both success and error messages from the server
            if (data.error) {
                setError(data.error);
            } else if (data.message) {
                setSuccess(data.message);
                setResetEmail('');
                // Switch back to login form after 3 seconds
                setTimeout(() => {
                    onBackToLogin();
                }, 3000);
            } else {
                throw new Error('Unexpected server response');
            }
        } catch (err) {
            setError(err.message || 'An error occurred');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="form-section reset-password-section">
            <h2>Reset Password</h2>
            <form onSubmit={handleResetPassword}>
                <div className="form-group">
                    <label>Email Address</label>
                    <input
                        type="email"
                        value={resetEmail}
                        onChange={(e) => setResetEmail(e.target.value)}
                        required
                        placeholder="Enter your email address"
                    />
                </div>
                {error && <div className="error-message">{error}</div>}
                {success && <div className="success-message">{success}</div>}
                <button type="submit" className="submit-button explore-now" disabled={isLoading}>
                    {isLoading ? 'Processing...' : 'Reset Password'}
                </button>
                <div className="form-links">
                    <button
                        type="button"
                        onClick={onBackToLogin}
                        className="link-button"
                    >
                        Back to Login
                    </button>
                </div>
            </form>
        </div>
    );
};

export default ForgotPassword;