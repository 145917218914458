import React from 'react';
import './AccidentReports.css';

const AccidentReports = () => {
    return (
        <div className="accidentreports-container">
            <div className="accidentreports-header">
                <h1>Accident Reports</h1>
            </div>
        </div>
    );
};

export default AccidentReports;