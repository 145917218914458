import React, { useState, useEffect } from 'react';
import { ApiRepos } from '../../../contracts/ContractVars';
import BusinessAddress from './BusinessAddress';
import './BusinessProfile.css';

const BusinessProfile = () => {
    const [businessData, setBusinessData] = useState({
        businessName: '',
        vatNumber: '',
        website: ''
    });

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');
    const [showAddAddressForm, setShowAddAddressForm] = useState(false);
    const [addresses, setAddresses] = useState([]);

    useEffect(() => {
        const fetchBusinessData = async () => {
            try {
                const response = await fetch(`${ApiRepos}account/current-user`, {
                    credentials: 'include'
                });
                if (!response.ok) throw new Error('Failed to fetch business data');
                const userData = await response.json();

                // Fetch business details using businessId from user data
                const businessResponse = await fetch(`${ApiRepos}business/${userData.businessId}`, {
                    credentials: 'include'
                });
                if (!businessResponse.ok) throw new Error('Failed to fetch business details');
                const businessDetails = await businessResponse.json();

                setBusinessData(businessDetails);

                // Fetch addresses separately
                const addressesResponse = await fetch(`${ApiRepos}business/${userData.businessId}/addresses`, {
                    credentials: 'include'
                });
                if (!addressesResponse.ok) throw new Error('Failed to fetch addresses');
                const addressesData = await addressesResponse.json();

                setAddresses(addressesData);

            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchBusinessData();
    }, []);    

    const handleAddressUpdated = (updatedAddress) => {
        setAddresses(prevAddresses =>
            prevAddresses.map(address =>
                address.id === updatedAddress.id ? updatedAddress : address
            )
        );
        setSuccessMessage('Address updated successfully');
    };

    const handleAddressDeleted = (deletedAddressId) => {
        setAddresses(prevAddresses =>
            prevAddresses.filter(address => address.id !== deletedAddressId)
        );
        setSuccessMessage('Address deleted successfully');
    };

    const handleAddressAdded = (newAddress) => {
        setAddresses(prevAddresses => [...prevAddresses, newAddress]);
        setSuccessMessage('Address added successfully');
        setShowAddAddressForm(false);
    };

    const handleBusinessUpdate = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${ApiRepos}business/update`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify(businessData)
            });

            if (!response.ok) throw new Error('Failed to update business profile');
            setSuccessMessage('Business profile updated successfully');
        } catch (err) {
            setError(err.message);
        }
    };

    if (loading) return <div className="profile-loading">Loading...</div>;
    if (error) return <div className="profile-error">{error}</div>;

    return (
        <div className="business-profile-container">
            <div className="business-form-wrapper">
                <h2 className="business-title">Business Profile</h2>
                {successMessage && (
                    <div className="success-message">{successMessage}</div>
                )}
                {error && (
                    <div className="error-message">{error}</div>
                )}

                <form onSubmit={handleBusinessUpdate} className="business-form">
                    <div className="form-section">
                        <div className="form-row">
                            <div className="form-group">
                                <label>Business Name *</label>
                                <input
                                    type="text"
                                    value={businessData.businessName || ''}
                                    onChange={(e) => setBusinessData({ ...businessData, businessName: e.target.value })}
                                    required
                                    maxLength={200}
                                />
                            </div>
                            <div className="form-group">
                                <label>VAT Number</label>
                                <input
                                    type="text"
                                    value={businessData.vatNumber || ''}
                                    onChange={(e) => setBusinessData({ ...businessData, vatNumber: e.target.value })}
                                    maxLength={20}
                                />
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-group">
                                <label>Website</label>
                                <input
                                    type="url"
                                    value={businessData.website || ''}
                                    onChange={(e) => setBusinessData({ ...businessData, website: e.target.value })}
                                    maxLength={255}
                                    placeholder="https://example.com"
                                />
                            </div>
                        </div>

                        <button type="submit" className="dashboard-button">
                            Update Business Profile
                        </button>
                    </div>

                    

                    <div className="form-section">
                        <h3>Business Addresses</h3>
                        <div className="addresses-grid">
                            {addresses.map(address => (
                                <BusinessAddress
                                    key={address.id}
                                    address={address}
                                    onAddressUpdated={handleAddressUpdated}
                                    onAddressDeleted={handleAddressDeleted}
                                />
                            ))}
                        </div>

                        <button
                            type="button"
                            className="dashboard-button"
                            onClick={() => setShowAddAddressForm(!showAddAddressForm)}
                        >
                            {showAddAddressForm ? 'Cancel Adding Address' : 'Add New Address'}
                        </button>

                        {showAddAddressForm && (
                            <div className="new-address-form">
                                <h4>Add New Address</h4>
                                <BusinessAddress
                                    address={{
                                        addressLine1: '',
                                        addressLine2: '',
                                        addressLine3: '',
                                        town: '',
                                        county: '',
                                        postCode: '',
                                        landlineNumber: '',
                                        isActive: true
                                    }}
                                    isNew={true}
                                    onAddressUpdated={handleAddressAdded}
                                    onCancel={() => setShowAddAddressForm(false)}
                                />
                            </div>
                        )}
                    </div>                    
                </form>
            </div>
        </div>
    );
};

export default BusinessProfile;