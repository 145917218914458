import React from 'react';
import './AccidentSupport.css';

const AccidentSupport = () => {
    return (
        <div className="accidentsupport-container">
            <div className="accidentsupport-header">
                <h1>Accident Support</h1>
            </div>
        </div>
    );
};

export default AccidentSupport;