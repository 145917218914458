import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { ApiRepos } from '../../../contracts/ContractVars';
import UserCard from './UserCard';
import './ManageUsers.css';

const ManageUsers = () => {
    const [users, setUsers] = useState([]);
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');
    const [showAddUserForm, setShowAddUserForm] = useState(false);

    useEffect(() => {
        fetchCurrentUserAndUsers();
    }, []);

    const fetchCurrentUserAndUsers = async () => {
        try {
            const currentUserResponse = await fetch(`${ApiRepos}account/current-user`, {
                credentials: 'include'
            });
            
            if (!currentUserResponse.ok) throw new Error('Failed to fetch current user');
            const currentUserData = await currentUserResponse.json();
            setCurrentUser(currentUserData);

            if (currentUserData.userLevel < 2) {
                setError('Unauthorized access');
                return;
            }

            const usersResponse = await fetch(`${ApiRepos}users`, {
                credentials: 'include'
            });
            
            if (!usersResponse.ok) throw new Error('Failed to fetch users');
            const usersData = await usersResponse.json();
            setUsers(usersData);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    const handleUserUpdated = (updatedUser) => {
        setUsers(prevUsers =>
            prevUsers.map(user =>
                user.id === updatedUser.id ? updatedUser : user
            )
        );
        setSuccessMessage('User updated successfully');
    };

    const handleStatusToggled = (userId, newStatus) => {
        setUsers(prevUsers =>
            prevUsers.map(user =>
                user.id === userId ? { ...user, isActive: newStatus } : user
            )
        );
        setSuccessMessage(`User ${newStatus ? 'activated' : 'deactivated'} successfully`);
    };

    const handleUserAdded = (newUser) => {
        setUsers(prevUsers => [...prevUsers, newUser]);
        setSuccessMessage('User added successfully');
        setShowAddUserForm(false);
    };

    if (!loading && (!currentUser || currentUser.userLevel < 2)) {
        return <Navigate to="/dashboard" replace />;
    }

    if (loading) return <div className="profile-loading">Loading...</div>;

    return (
        <div className="dashboard-page-content">
            <h1 className="page-title">MANAGE USERS</h1>

            {error && (
                <div className="error-message">{error}</div>
            )}
            {successMessage && (
                <div className="success-message">{successMessage}</div>
            )}

            <div className="content-actions">
                <button
                    className="dashboard-button"
                    onClick={() => setShowAddUserForm(!showAddUserForm)}
                >
                    {showAddUserForm ? 'CANCEL ADDING USER' : 'ADD NEW USER'}
                </button>
            </div>

            <div className="white-container">
                {showAddUserForm && (
                    <div className="new-user-section">
                        <h2>Add New User</h2>
                        <UserCard
                            user={{
                                firstName: '',
                                lastName: '',
                                emailAddress: '',
                                mobileNumber: '',
                                userLevel: 1,
                                isActive: true,
                                profilePictureUrl: ''
                            }}
                            isNew={true}
                            onUserUpdated={handleUserAdded}
                            onCancel={() => setShowAddUserForm(false)}
                            currentUserLevel={currentUser.userLevel}
                        />
                    </div>
                )}

                <div className="users-grid">
                    {users.map(user => (
                        <UserCard
                            key={user.id}
                            user={user}
                            onUserUpdated={handleUserUpdated}
                            onStatusToggled={handleStatusToggled}
                            currentUserLevel={currentUser.userLevel}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ManageUsers;