import React, { useState, useEffect } from 'react';
import { useLocation, Link, useSearchParams, useNavigate } from 'react-router-dom';
import '../../assets/css/bootstrap.min.css';
import '../../assets/css/index.css';
import './Home.css';
import { ApiRepos } from '../../contracts/ContractVars';
import Header from './components/Header';
import Footer from './components/Footer';
import Login from './components/Login';
import Register from './components/Register';
import Contact from './components/Contact';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';

const Home = () => {
    const [message, setMessage] = useState('');
    const [isAuthenticated, setIsAuthenticated] = useState(null);
    const [activeForm, setActiveForm] = useState('login');
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    useEffect(() => {
        // Handle the initial form state based on path and token
        const handleInitialFormState = () => {
            const token = searchParams.get('token');
            const path = location.pathname;

            if (path === '/reset-password' && token) {
                setActiveForm('reset');
            } else if (path === '/reset-password' && !token) {
                // If someone tries to access reset-password without a token, redirect to home
                navigate('/', { replace: true });
            } else if (token) {
                // If we're on any other path with a token, redirect to reset-password
                navigate(`/reset-password?token=${token}`, { replace: true });
            }
        };

        handleInitialFormState();

        if (location.state && location.state.message) {
            setMessage(location.state.message);
            window.history.replaceState({}, document.title);
        }

        const checkAuth = async () => {
            try {
                const response = await fetch(`${ApiRepos}account/verify`, {
                    method: 'GET',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                const data = await response.json();
                if (!response.ok) {
                    throw new Error(data.error || 'Authentication check failed.');
                }
                setIsAuthenticated(data.isAuthenticated);
            } catch (err) {
                console.error('Authentication check error:', err);
                setIsAuthenticated(false);
            }
        };

        checkAuth();
    }, [location, searchParams, navigate]);

    const handleLoginAttempt = () => {
        setMessage('');
    };

    const handleBackToLogin = () => {
        setActiveForm('login');
        navigate('/', { replace: true });
    };

    const renderForm = () => {
        switch (activeForm) {
            case 'register':
                return <Register onBackToLogin={handleBackToLogin} />;
            case 'forgot':
                return <ForgotPassword onBackToLogin={handleBackToLogin} />;
            case 'reset':
                return <ResetPassword onBackToLogin={handleBackToLogin} />;
            default:
                return (
                    <>
                        <Login
                            onRegisterClick={() => setActiveForm('register')}
                            onForgotClick={() => setActiveForm('forgot')}
                            onLoginAttempt={handleLoginAttempt}
                        />
                        <Contact />
                    </>
                );
        }
    };

    return (
        <div className="home-container">
            <Header isAuthenticated={isAuthenticated} />
            <main className="main-content">
                <div className="page-title-section">
                    <h1>BUSINESS DASHBOARD</h1>
                    <div className="subtitle">MOTORENT - THE HIRE SOLUTION</div>
                </div>
                {message && (
                    <div className="alert alert-info">
                        {message}
                    </div>
                )}
                {isAuthenticated ? (
                    <div className="go-to-dashboard">
                        <Link to="/dashboard" className="dashboard-button">Go to Dashboard</Link>
                    </div>
                ) : (
                    <div className="forms-container">
                        {renderForm()}
                    </div>
                )}
            </main>
            <Footer />
        </div>
    );
};

export default Home;