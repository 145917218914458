import React, { useState, useEffect } from 'react';
import { Route, Routes, useNavigate, Navigate } from 'react-router-dom';
import DashboardHeader from './components/DashboardHeader';
import DashboardFooter from './components/DashboardFooter';
import Sidebar from './components/Sidebar';
import DashboardHome from './home/DashboardHome';
import QuoteRequest from './order/QuoteRequest';
import OrderVehicle from './order/OrderVehicle';
import AccidentSupport from './accident-support/AccidentSupport';
import FAQs from './faqs/FAQs';
import UserProfile from './profile/UserProfile';
import BusinessProfile from './business-profile/BusinessProfile';
import ManageUsers from './manage-users/ManageUsers';

import ViewQuotes from './quotes/ViewQuotes';
import ViewOrders from './orders/ViewOrders';
import ViewQuote from './quotes/ViewQuote';
import ViewOrder from './orders/ViewOrder';
import AccidentReports from './accidents/AccidentReports';
import BusinessProfiles from './business-profiles/BusinessProfiles';
import Settings from './settings/Settings';
import Reporting from './reporting/Reporting';

import { useUser } from '../../UserContext';
import './Dashboard.css';

const Dashboard = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const navigate = useNavigate();
    const { user, loading, initialized, fetchUserProfile } = useUser();

    useEffect(() => {
        if (!initialized) {
            fetchUserProfile();
        }
    }, [initialized, fetchUserProfile]);

    useEffect(() => {
        if (initialized && !user) {
            navigate('/', { replace: true });
        }
    }, [initialized, user, navigate]);

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    if (loading || !initialized) {
        return <div className="loading">Loading...</div>;
    }

    if (!user) {
        return null;
    }

    return (
        <div className='dashboard-page'>
            <DashboardHeader
                toggleSidebar={toggleSidebar}
                opened={sidebarOpen}
                userData={user}
            />
            <div className="dashboard-main">
                <Sidebar toggleSidebar={toggleSidebar} opened={sidebarOpen} />
                <div className="dashboard-page-content">
                    <Routes>
                        <Route path="/" element={<Navigate to="home" replace />} />
                        <Route path="home" element={<DashboardHome userData={user} />} />
                        <Route path="quote" element={<QuoteRequest userData={user} />} />
                        <Route path="order" element={<OrderVehicle userData={user} />} />
                        <Route path="accident-support" element={<AccidentSupport userData={user} />} />
                        <Route path="faqs" element={<FAQs />} />
                        <Route path="profile" element={<UserProfile userData={user} />} />
                        <Route path="business-profile" element={<BusinessProfile userData={user} />} />
                        <Route path="manage-users" element={<ManageUsers userData={user} />} />

                        <Route path="quotes" element={<ViewQuotes userData={user} />} />
                        <Route path="quotes/:id" element={<ViewQuote userData={user} />} />
                        <Route path="orders" element={<ViewOrders userData={user} />} />
                        <Route path="orders/:id" element={<ViewOrder userData={user} />} />

                        <Route path="accident-reports" element={<AccidentReports userData={user} />} />
                        <Route path="business-profiles" element={<BusinessProfiles userData={user} />} />
                        <Route path="reporting" element={<Reporting userData={user} />} />
                        <Route path="settings" element={<Settings userData={user} />} />
                    </Routes>
                    <DashboardFooter />
                </div>
            </div>
        </div>
    );
};

export default Dashboard;