import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApiRepos } from '../../../contracts/ContractVars';
import './Quotes.css';

const ViewQuotes = ({ userData }) => {
    const [quotes, setQuotes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchQuotes = async () => {
            try {
                const response = await fetch(`${ApiRepos}order?status=0`, {
                    credentials: 'include'
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch quotes');
                }

                let data = await response.json();

                // Filter for user level 1 (standard users)
                if (userData.userLevel === 1) {
                    data = data.filter(quote => quote.requestedByUser === `${userData.firstName} ${userData.lastName}`);
                }

                setQuotes(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchQuotes();
    }, [userData]);

    const formatDateTime = (date, hour, minute) => {
        return `${new Date(date).toLocaleDateString()} ${hour}:${minute}`;
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <div className="p-6">
            <div className="flex justify-between items-center mb-6">
                <h2 className="text-2xl font-bold">Quote Requests</h2>
                <button
                    onClick={() => navigate('/dashboard/quote')}
                    className="dashboard-button">
                    New Quote Request
                </button>
            </div>

            <div className="overflow-x-auto bg-white rounded-lg shadow">
                <table className="min-w-full">
                    <thead className="bg-gray-50">
                        <tr>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Ref</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Driver</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Hire From</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Hire To</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Logistics</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Quote Amount</th>
                            {userData.userLevel > 1 && (
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Requested By</th>
                            )}
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Request Date</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Actions</th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                        {quotes.map(quote => (
                            <tr key={quote.id} className="hover:bg-gray-50">
                                <td className="px-6 py-4">{quote.id}</td>
                                <td className="px-6 py-4">{quote.driverName}</td>
                                <td className="px-6 py-4">{formatDateTime(quote.hireFromDate, quote.hireFromHour, quote.hireFromMinute)}</td>
                                <td className="px-6 py-4">{formatDateTime(quote.hireToDate, quote.hireToHour, quote.hireToMinute)}</td>
                                <td className="px-6 py-4">{quote.logistics}</td>
                                <td className="px-6 py-4">
                                    {quote.quoteAmount ? `�${quote.quoteAmount.toFixed(2)}` : 'Pending'}
                                </td>
                                {userData.userLevel > 1 && (
                                    <td className="px-6 py-4">{quote.requestedByUser}</td>
                                )}
                                <td className="px-6 py-4">{new Date(quote.createdDate).toLocaleString()}</td>
                                <td className="px-6 py-4">
                                    <button
                                        onClick={() => navigate(`/dashboard/quotes/${quote.id}`)}
                                        className="qr-submit-button2">
                                        View Details
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default ViewQuotes;