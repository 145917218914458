import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApiRepos } from '../../../contracts/ContractVars';
import './Orders.css';

const ViewOrders = ({ userData }) => {
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const response = await fetch(`${ApiRepos}order?status=1`, {
                    credentials: 'include'
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch orders');
                }

                let data = await response.json();

                // Filter for user level 1 (standard users)
                if (userData.userLevel === 1) {
                    data = data.filter(order => order.requestedByUser === `${userData.firstName} ${userData.lastName}`);
                }

                setOrders(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchOrders();
    }, [userData]);

    const formatDateTime = (date, hour, minute) => {
        return `${new Date(date).toLocaleDateString()} ${hour}:${minute}`;
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <div className="p-6">
            <div className="flex justify-between items-center mb-6">
                <h2 className="text-2xl font-bold">Orders</h2>
                <button
                    onClick={() => navigate('/dashboard/order')}
                    className="dashboard-button">
                    New Order
                </button>
            </div>

            <div className="overflow-x-auto bg-white rounded-lg shadow">
                <table className="min-w-full">
                    <thead className="bg-gray-50">
                        <tr>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Ref</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Driver</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Hire From</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Hire To</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Logistics</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">PO Number</th>
                            {userData.userLevel > 1 && (
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Ordered By</th>
                            )}
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Order Date</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Actions</th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                        {orders.map(order => (
                            <tr key={order.id} className="hover:bg-gray-50">
                                <td className="px-6 py-4">{order.id}</td>
                                <td className="px-6 py-4">{order.driverName}</td>
                                <td className="px-6 py-4">{formatDateTime(order.hireFromDate, order.hireFromHour, order.hireFromMinute)}</td>
                                <td className="px-6 py-4">{formatDateTime(order.hireToDate, order.hireToHour, order.hireToMinute)}</td>
                                <td className="px-6 py-4">{order.logistics}</td>
                                <td className="px-6 py-4">{order.poNumber || 'N/A'}</td>
                                {userData.userLevel > 1 && (
                                    <td className="px-6 py-4">{order.requestedByUser}</td>
                                )}
                                <td className="px-6 py-4">{new Date(order.createdDate).toLocaleString()}</td>
                                <td className="px-6 py-4">
                                    <button
                                        onClick={() => navigate(`/dashboard/orders/${order.id}`)}
                                        className="qr-submit-button2">
                                        View Details
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default ViewOrders;