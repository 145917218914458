import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ApiRepos } from '../../../contracts/ContractVars';

const ResetPassword = ({ onBackToLogin }) => {
    const [searchParams] = useSearchParams();
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const token = searchParams.get('token');

    const validatePassword = (password) => {
        return password && password.length >= 8;
    };

    const handleResetPassword = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError('');
        setSuccess('');

        try {
            if (!validatePassword(newPassword)) {
                setError('Password must be at least 8 characters long');
                return;
            }

            if (newPassword !== confirmPassword) {
                setError('Passwords do not match');
                return;
            }

            if (!token) {
                setError('Invalid reset token');
                return;
            }

            const response = await fetch(`${ApiRepos}account/reset-password`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    token: token,
                    newPassword: newPassword
                })
            });

            const data = await response.json();

            if (data.error) {
                setError(data.error);
            } else if (data.message) {
                setSuccess(data.message);
                // Clear form
                setNewPassword('');
                setConfirmPassword('');
                // Switch back to login form after 3 seconds on success
                setTimeout(() => {
                    onBackToLogin();
                }, 3000);
            } else {
                throw new Error('Unexpected server response');
            }
        } catch (err) {
            setError(err.message || 'An error occurred');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="form-section reset-password-section">
            <h2>Set New Password</h2>
            <form onSubmit={handleResetPassword}>
                <div className="form-group">
                    <label>New Password</label>
                    <input
                        type="password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        required
                        placeholder="Enter your new password"
                        minLength="8"
                    />
                </div>
                <div className="form-group">
                    <label>Confirm Password</label>
                    <input
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                        placeholder="Confirm your new password"
                        minLength="8"
                    />
                </div>
                {error && <div className="error-message">{error}</div>}
                {success && <div className="success-message">{success}</div>}
                <button type="submit" className="submit-button explore-now" disabled={isLoading}>
                    {isLoading ? 'Processing...' : 'Reset Password'}
                </button>
                <div className="form-links">
                    <button
                        type="button"
                        onClick={onBackToLogin}
                        className="link-button"
                    >
                        Back to Login
                    </button>
                </div>
            </form>
        </div>
    );
};

export default ResetPassword;