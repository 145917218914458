import React, { useState, useEffect } from 'react';
import { ApiRepos } from '../../../contracts/ContractVars';
import './Settings.css';

const Settings = () => {
    const [vehicleTypes, setVehicleTypes] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showDialog, setShowDialog] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [currentType, setCurrentType] = useState(null);
    const [formData, setFormData] = useState({
        name: '',
        description: ''
    });

    useEffect(() => {
        fetchVehicleTypes();
    }, []);

    const fetchVehicleTypes = async () => {
        try {
            const response = await fetch(`${ApiRepos}lookup/vehicletypes`, {
                credentials: 'include'
            });
            if (!response.ok) throw new Error('Failed to fetch vehicle types');
            const data = await response.json();
            setVehicleTypes(data);
        } catch (err) {
            setError(err.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const url = currentType
                ? `${ApiRepos}lookup/vehicletypes/${currentType.id}`
                : `${ApiRepos}lookup/vehicletypes`;

            const response = await fetch(url, {
                method: currentType ? 'PUT' : 'POST',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                body: JSON.stringify(formData)
            });

            if (!response.ok) throw new Error('Failed to save vehicle type');

            await fetchVehicleTypes();
            setShowDialog(false);
            setFormData({ name: '', description: '' });
            setCurrentType(null);
            setSuccessMessage(currentType ? 'Vehicle type updated successfully' : 'Vehicle type created successfully');

            setTimeout(() => setSuccessMessage(''), 3000);
        } catch (err) {
            setError(err.message);
        }
    };

    const toggleStatus = async (type) => {
        try {
            const url = `${ApiRepos}lookup/vehicletypes/${type.id}/restore?isActive=${!type.isActive}`;

            const response = await fetch(url, {
                method: 'PUT',
                credentials: 'include'
            });

            if (!response.ok) throw new Error(`Failed to ${type.isActive ? 'hide' : 'show'} vehicle type`);

            await fetchVehicleTypes();
            setSuccessMessage(`Vehicle type ${type.isActive ? 'hidden' : 'shown'} successfully`);
            setTimeout(() => setSuccessMessage(''), 3000);
        } catch (err) {
            setError(err.message);
        }
    };

    const openDialog = (type = null) => {
        if (type) {
            setFormData({ name: type.name, description: type.description });
            setCurrentType(type);
        } else {
            setFormData({ name: '', description: '' });
            setCurrentType(null);
        }
        setShowDialog(true);
    };

    if (isLoading) return <div className="settings-page-loading">Loading...</div>;

    return (
        <div className="settings-page">
            <div className="settings-page-header">
                <h1>Settings</h1>
                <button
                    className="dashboard-button"
                    onClick={() => openDialog()}
                >
                    Add Vehicle Type
                </button>
            </div>

            {error && <div className="settings-page-error">{error}</div>}
            {successMessage && <div className="settings-page-success">{successMessage}</div>}

            <div className="settings-page-content">
                <table className="settings-page-table">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Description</th>
                            <th>Status</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {vehicleTypes.map(type => (
                            <tr key={type.id} className={!type.isActive ? 'settings-page-inactive-row' : ''}>
                                <td>{type.name}</td>
                                <td>{type.description}</td>
                                <td>
                                    <span className={`settings-page-status ${type.isActive ? 'active' : 'inactive'}`}>
                                        {type.isActive ? 'Shown' : 'Hidden'}
                                    </span>
                                </td>
                                <td className="settings-page-actions">
                                    <button
                                        className="settings-page-edit-button"
                                        onClick={() => openDialog(type)}
                                    >
                                        Edit
                                    </button>
                                    <button
                                        className={type.isActive ? 'settings-page-hide-button' : 'settings-page-show-button'}
                                        onClick={() => toggleStatus(type)}
                                    >
                                        {type.isActive ? 'Hide' : 'Show'}
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {showDialog && (
                <div className="settings-page-modal-overlay">
                    <div className="settings-page-modal">
                        <h2>{currentType ? 'Edit Vehicle Type' : 'Add Vehicle Type'}</h2>
                        <form onSubmit={handleSubmit}>
                            <div className="settings-page-form-group">
                                <label>Name *</label>
                                <input
                                    type="text"
                                    value={formData.name}
                                    onChange={(e) => setFormData(prev => ({
                                        ...prev,
                                        name: e.target.value
                                    }))}
                                    required
                                    maxLength={100}
                                />
                            </div>
                            <div className="settings-page-form-group">
                                <label>Description</label>
                                <textarea
                                    value={formData.description}
                                    onChange={(e) => setFormData(prev => ({
                                        ...prev,
                                        description: e.target.value
                                    }))}
                                    maxLength={500}
                                    rows={4}
                                />
                            </div>
                            <div className="settings-page-modal-actions">
                                <button
                                    type="button"
                                    className="settings-page-cancel-button"
                                    onClick={() => setShowDialog(false)}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="settings-page-submit-button"
                                >
                                    {currentType ? 'Update' : 'Create'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Settings;