// import motorent from '../../../assets/images/motorent.avif'


const Header = ({ openLoginModal, isAuthenticated }) => {
    return (
        <>
            <header className="shadow-sm header header_theme_default">
            <bsk-header>
                <div className="navbar navbar-light bg-white navbar--header-main py-lg-3">
                <div className="container-fluid flex-nowrap">
                    <span className="flex-c justify-content-start">
                        <span slot="left-nav">
                            <div className="navbar-nav flex-row gap-6 d-flex align-items-center">
                            <div className="px-6 border-end border-light d-none d-lg-flex">
                                            <a href="https://www.google.com/maps/place/Motorent/@50.8438789,-0.9881505,15z/data=!4m8!3m7!1s0x487444cc8a472531:0xa5fd9d3430ae72c4!8m2!3d50.8438789!4d-0.9881505!9m1!1b1!16s%2Fg%2F11clwmp6t3?entry=ttu" target="motorent">
                                <img src="https://bluesky.sirv.com/Websites/Motorent/images/header/google-reviews.png" alt="Reviews" width="126" height="41" />
                                </a>
                            </div>
                            <div className="d-lg-none d-flex gap-6 ps-md-5">
                                            <a href="https://www.google.com/maps/place/Motorent/@50.8438789,-0.9881505,15z/data=!4m8!3m7!1s0x487444cc8a472531:0xa5fd9d3430ae72c4!8m2!3d50.8438789!4d-0.9881505!9m1!1b1!16s%2Fg%2F11clwmp6t3?entry=ttu" target="motorent">
                                <img src="https://bluesky.sirv.com/Websites/Motorent/images/header/google.png" alt="Reviews" width="20" height="20" />
                                </a>
                                <a className="nav-link gap-2 h5 mb-0 d-flex align-items-center gtm-track--tel gtm-track--tel_8878" href="tel:02392002002">
                                    <i className="fa-regular fa-phone me-1"></i> 
                                </a>
                            </div>
                            <ul className="list-unstyled d-none d-lg-flex gap-6 mb-0 align-items-center">
                                <li className="nav-item h5 mb-0">
                                                <a className="nav-link" target="motorent" href="https://www.facebook.com/MotorentHavant/">
                                        <i className="fab fa-facebook-f"></i> 
                                    </a>
                                </li>
                                <li className="nav-item h5 mb-0">
                                                <a className="nav-link" target="motorent" href="https://www.linkedin.com/company/southern-motor-contracts-ltd/">
                                        <i className="fab fa-linkedin-in"></i> 
                                    </a>
                                </li>
                                <li className="nav-item h5 mb-0">
                                                <a className="nav-link" target="motorent" href="https://www.instagram.com/motorent/">
                                        <i className="fab fa-instagram"></i> 
                                    </a>
                                </li>
                            </ul>
                            </div>
                        </span>
                    </span>
                    <a className="navbar-brand justify-content-center" href="/" aria-label="Boilerplate"><img alt="logo" className="header-logo" src={'../assets/images/motorent.avif'} width="173" height="50" /></a>
                    <nav className="collapse navbar-collapse justify-content-end d-none d-md-flex" id="navBar">
                        <span slot="nav" className="d-flex gap-3">
                            <ul className="navbar-nav flex-row gap-6 d-none d-sm-flex">
                            <li className="nav-item">
                                            <a className="nav-link gap-2 d-flex align-items-center" href="https://www.motorent.biz/faqs/" target="motorent">
                                    <i className="fa-regular fa-circle-question me-1"></i> 
                                    <span className="d-none d-xl-block">
                                    FAQs
                                    </span>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link gap-2 d-flex align-items-center gtm-track--tel gtm-track--tel_8878" href="tel:02392002002">
                                    <i className="fa-regular fa-phone me-1"></i> 
                                    <span className="d-none d-xl-block">
                                    02392 002002
                                    </span>
                                </a>
                            </li>
                            <li className="nav-item">
                                            <a className="nav-link gap-2 d-flex align-items-center" href="https://www.motorent.biz/find-us/" target="motorent">
                                    <i className="fa-regular fa-location-dot me-1"></i> 
                                    <span className="d-none d-xl-block">
                                    Find Us
                                    </span>
                                </a>
                            </li>
                            </ul>
                        </span>
                    </nav>
                </div>
                </div>
            </bsk-header>
        </header>
        </>
    );
};

export default Header;