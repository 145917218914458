import React from 'react';

const DashboardHome = ({ userData }) => {
    return (
        <div className="dashboard-content">
            <div className="content-wrapper">
                <div className="text-center">
                    <h1>Hello, {userData.firstName}!</h1>
                    <p>Welcome to your dashboard</p>
                </div>
            </div>
        </div>
    );
};

export default DashboardHome;