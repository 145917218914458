import React from 'react';

export const TimeSelector = ({ selectedHour, selectedMinute, onHourChange, onMinuteChange }) => {
    const hours = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11',
        '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];
    const minutes = ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55'];

    return (
        <div className="time-selector">
            <select
                value={selectedHour}
                onChange={(e) => onHourChange(e.target.value)}
                className="time-select"
            >
                {hours.map(hour => (
                    <option key={hour} value={hour}>{hour}</option>
                ))}
            </select>
            <span className="time-separator">:</span>
            <select
                value={selectedMinute}
                onChange={(e) => onMinuteChange(e.target.value)}
                className="time-select"
            >
                {minutes.map(minute => (
                    <option key={minute} value={minute}>{minute}</option>
                ))}
            </select>
        </div>
    );
};