import React from 'react';

const DashboardFooter = () => {
    return (
        <footer className="dashboard-footer">
            <div className="footer-content">
                <div className="copyright">
                    &copy; {new Date().getFullYear()} Motorent. All rights reserved.
                </div>

                <div className="footer-links">
                    <a
                        href="https://www.motorent.biz/privacy-policy/"
                        target="motorent"
                        rel="noopener noreferrer"
                    >
                        Privacy Policy
                    </a>
                    <a
                        href="https://www.motorent.biz/terms-and-conditions/"
                        target="motorent"
                        rel="noopener noreferrer"
                    >
                        Terms & Conditions
                    </a>
                    <a
                        href="https://bluesky-cogcms.cdn.imgeng.in/media/zujjxdba/rental-agreement-terms-and-condition.pdf"
                        target="motorent"
                        rel="noopener noreferrer"
                    >
                        Rental Terms
                    </a>
                </div>
            </div>
        </footer>
    );
};

export default DashboardFooter;