import React, { useState } from 'react';
import { ApiRepos } from '../../../contracts/ContractVars';

const Register = ({ onBackToLogin }) => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        emailAddress: '',
        mobileNumber: '',
        password: '',
        confirmPassword: '',
        businessRegistrationCode: ''
    });
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleRegister = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError('');

        if (formData.password !== formData.confirmPassword) {
            setError("Passwords don't match");
            setIsLoading(false);
            return;
        }

        try {
            const response = await fetch(`${ApiRepos}account/register`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formData)
            });

            const data = await response.json();
            if (!response.ok) throw new Error(data.error || 'Registration failed');

            // On successful registration:
            // 1. Switch back to login form
            onBackToLogin();
            // 2. The message will be shown by Home component due to the state we're setting
            window.history.replaceState(
                { message: 'Registration successful! Please login.' },
                document.title
            );
            // 3. Force a reload to ensure the message is displayed
            window.location.reload();
        } catch (err) {
            setError(err.message || 'Registration failed');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="form-section register-section">
            <h2>Register New Account</h2>
            <form onSubmit={handleRegister}>
                <div className="form-row">
                    <div className="form-group half">
                        <label>First Name</label>
                        <input
                            type="text"
                            value={formData.firstName}
                            onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
                            required
                            placeholder="Enter your first name"
                        />
                    </div>
                    <div className="form-group half">
                        <label>Last Name</label>
                        <input
                            type="text"
                            value={formData.lastName}
                            onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
                            required
                            placeholder="Enter your last name"
                        />
                    </div>
                </div>
                <div className="form-group">
                    <label>Email Address</label>
                    <input
                        type="email"
                        value={formData.emailAddress}
                        onChange={(e) => setFormData({ ...formData, emailAddress: e.target.value })}
                        required
                        placeholder="Enter your email address"
                    />
                </div>
                <div className="form-group">
                    <label>Mobile Number</label>
                    <input
                        type="tel"
                        value={formData.mobileNumber}
                        onChange={(e) => setFormData({ ...formData, mobileNumber: e.target.value })}
                        required
                        placeholder="Enter your UK mobile number"
                    />
                </div>
                <div className="form-group">
                    <label>Password</label>
                    <input
                        type="password"
                        value={formData.password}
                        onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                        required
                        placeholder="Enter your password"
                    />
                </div>
                <div className="form-group">
                    <label>Confirm Password</label>
                    <input
                        type="password"
                        value={formData.confirmPassword}
                        onChange={(e) => setFormData({ ...formData, confirmPassword: e.target.value })}
                        required
                        placeholder="Confirm your password"
                    />
                </div>
                <div className="form-group">
                    <label>Business Registration Code</label>
                    <label>Please contact the office on 02392002002 to obtain your business registration code.</label>
                    <input
                        type="text"
                        value={formData.businessRegistrationCode}
                        onChange={(e) => setFormData({ ...formData, businessRegistrationCode: e.target.value })}
                        required
                        placeholder="Enter your business registration code"
                    />
                </div>
                {error && <div className="error-message">{error}</div>}
                <button type="submit" className="submit-button explore-now" disabled={isLoading}>
                    {isLoading ? 'Registering...' : 'Register'}
                </button>
                <div className="form-links">
                    <button
                        type="button"
                        onClick={onBackToLogin}
                        className="link-button"
                    >
                        Back to Login
                    </button>
                </div>
            </form>
        </div>
    );
};

export default Register;