import React from 'react';
import './Reporting.css';

const Reporting = () => {
    return (
        <div className="reporting-container">
            <div className="reporting-header">
                <h1>Reporting</h1>
            </div>
        </div>
    );
};

export default Reporting;