import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApiRepos } from '../../../contracts/ContractVars';
import { TimeSelector } from './TimeSelector';
import './OrderVehicle.css';

const getTodayString = () => {
    return new Date().toISOString().split('T')[0];
};

const QuoteRequest = () => {
    const navigate = useNavigate(); 
    const [formData, setFormData] = useState({
        isDriver: false,
        driverName: '',
        driverEmail: '',
        mobile: '',
        vehicleType: '',
        poNumber: '',
        logistics: '',
        addressLine1: '',
        addressLine2: '',
        town: '',
        postCode: '',
        notes: '',
        purpose: '',
        hireFromDate: getTodayString(),
        hireFromHour: '09',
        hireFromMinute: '00',
        hireToDate: getTodayString(),
        hireToHour: '17',
        hireToMinute: '00'
    });

    const [vehicleTypes, setVehicleTypes] = useState([]);
    const [businessAddresses, setBusinessAddresses] = useState([]);
    const [showDeliveryAddress, setShowDeliveryAddress] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const validateUKMobile = (phone) => {
        const regex = /^(?:(?:\+44)|(?:0))7\d{9}$/;
        return regex.test(phone.replace(/\s+/g, ''));
    };

    useEffect(() => {
        const fetchInitialData = async () => {
            setLoading(true);
            try {
                // Fetch current user
                const userResponse = await fetch(`${ApiRepos}account/current-user`, {
                    credentials: 'include'
                });
                if (!userResponse.ok) throw new Error('Failed to fetch user data');
                const userData = await userResponse.json();
                setCurrentUser(userData);

                // Fetch vehicle types
                const typesResponse = await fetch(`${ApiRepos}lookup/vehicletypes/active`, {
                    credentials: 'include'
                });
                if (!typesResponse.ok) throw new Error('Failed to fetch vehicle types');
                const typesData = await typesResponse.json();
                setVehicleTypes(typesData);

            } catch (err) {
                setError(err.message);
                console.error('Error fetching initial data:', err);
            } finally {
                setLoading(false);
            }
        };

        fetchInitialData();
    }, []);

    const handleDriverCheckboxChange = (e) => {
        const isChecked = e.target.checked;
        setFormData({
            ...formData,
            isDriver: isChecked,
            driverName: isChecked && currentUser ? `${currentUser.firstName} ${currentUser.lastName}` : '',
            driverEmail: isChecked && currentUser ? currentUser.emailAddress : '',
            mobile: isChecked && currentUser ? currentUser.mobileNumber : ''
        });
    };

    const handleLogisticsChange = async (value) => {
        setFormData({ ...formData, logistics: value });
        if (value === 'Delivery') {
            setShowDeliveryAddress(true);
            try {
                const businessId = currentUser?.businessId;
                const response = await fetch(`${ApiRepos}business/${businessId}/addresses`, {
                    credentials: 'include'
                });
                if (!response.ok) throw new Error('Failed to fetch addresses');
                const data = await response.json();
                setBusinessAddresses(data);
            } catch (error) {
                console.error('Error fetching business addresses:', error);
                setError('Failed to load delivery addresses');
            }
        } else {
            setShowDeliveryAddress(false);
        }
    };

    const handleAddressSelection = (addressId) => {
        const selected = businessAddresses.find(addr => addr.id === parseInt(addressId));
        if (selected) {
            setFormData({
                ...formData,
                addressLine1: selected.addressLine1,
                addressLine2: selected.addressLine2 || '',
                town: selected.town,
                postCode: selected.postCode
            });
        }
    };


    const resetDatesToToday = () => {
        const today = getTodayString();
        setFormData(prev => ({
            ...prev,
            hireFromDate: today,
            hireToDate: today
        }));
    };

    const validateDateTimes = () => {
        const fromDateTime = new Date(
            `${formData.hireFromDate}T${formData.hireFromHour}:${formData.hireFromMinute}:00`
        );
        const toDateTime = new Date(
            `${formData.hireToDate}T${formData.hireToHour}:${formData.hireToMinute}:00`
        );

        // Check if dates are valid
        if (isNaN(fromDateTime.getTime()) || isNaN(toDateTime.getTime())) {
            setError('Please enter valid dates and times');
            resetDatesToToday();
            return false;
        }

        // Check if from date is in the future
        const now = new Date();
        if (fromDateTime < now) {
            setError('Hire from date must be in the future');
            resetDatesToToday();
            return false;
        }

        // Check if from date is before to date
        if (fromDateTime >= toDateTime) {
            setError('Hire from date/time must be before hire to date/time');
            resetDatesToToday();
            return false;
        }

        // Calculate the difference in hours
        const hoursDifference = (toDateTime - fromDateTime) / (1000 * 60 * 60);

        // Minimum hire period (e.g., 1 hour)
        if (hoursDifference < 1) {
            setError('Minimum hire period is 1 hour');
            resetDatesToToday();
            return false;
        }

        return true;
    };

    const handleFromDateChange = (e) => {
        const newFromDate = e.target.value;
        const today = getTodayString();

        // If selected date is in the past, reset to today
        if (newFromDate < today) {
            setError('Cannot select a past date');
            resetDatesToToday();
            return;
        }

        // Update from date and set to date to match if it's before the new from date
        if (formData.hireToDate < newFromDate) {
            setFormData({
                ...formData,
                hireFromDate: newFromDate,
                hireToDate: newFromDate
            });
        } else {
            setFormData({
                ...formData,
                hireFromDate: newFromDate
            });
        }
        setError(null);
    };

    const handleToDateChange = (e) => {
        const newToDate = e.target.value;
        const today = getTodayString();

        // If selected date is in the past, reset to today
        if (newToDate < today) {
            setError('Cannot select a past date');
            resetDatesToToday();
            return;
        }

        // If to date is before from date, update both
        if (newToDate < formData.hireFromDate) {
            setFormData({
                ...formData,
                hireFromDate: newToDate,
                hireToDate: newToDate
            });
        } else {
            setFormData({
                ...formData,
                hireToDate: newToDate
            });
        }
        setError(null);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateUKMobile(formData.mobile)) {
            setError('Please enter a valid UK mobile number');
            return;
        }

        // Validate dates and times
        if (!validateDateTimes()) {
            return;
        }

        const submitData = {
            driverName: formData.driverName,
            driverEmail: formData.driverEmail,
            mobile: formData.mobile,
            vehicleTypeId: parseInt(formData.vehicleType),
            poNumber: formData.poNumber,
            logistics: formData.logistics,
            addressLine1: formData.addressLine1,
            addressLine2: formData.addressLine2,
            town: formData.town,
            postCode: formData.postCode,
            notes: formData.notes,
            purpose: formData.purpose,
            hireFromDate: formData.hireFromDate,
            hireFromHour: formData.hireFromHour,
            hireFromMinute: formData.hireFromMinute,
            hireToDate: formData.hireToDate,
            hireToHour: formData.hireToHour,
            hireToMinute: formData.hireToMinute
        };

        try {
            const response = await fetch(`${ApiRepos}order/quote`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify(submitData)
            });

            if (!response.ok) {
                throw new Error('Failed to submit quote request');
            }

            const quoteData = await response.json();
            alert('Quote request submitted successfully');
            // Navigate to view quote page
            navigate(`/quotes/view/${quoteData.id}`);
        } catch (err) {
            setError('Failed to submit quote request: ' + err.message);
        }
    };

    if (loading) return <div className="qr-loading">Loading...</div>;    

    return (
        <div className="qr-container">
            <div className="qr-form-wrapper">
                <h2 className="qr-title">Request Quote</h2>
                {/* Error message section */}
                {error && (
                    <div className="qr-error-container">
                        <div className="qr-error-message">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="qr-error-icon"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                                    clipRule="evenodd"
                                />
                            </svg>
                            {error}
                        </div>
                    </div>
                )}
                <form onSubmit={handleSubmit} className="qr-form">
                    <div className="qr-form-section">
                        {/* Driver checkbox and details section */}
                        <div className="qr-form-row">
                            <div className="qr-form-group">
                                <label className="qr-checkbox-label">
                                    <input
                                        type="checkbox"
                                        checked={formData.isDriver}
                                        onChange={handleDriverCheckboxChange}
                                        className="qr-checkbox-input"
                                    />
                                    Tick if you will be the driver
                                </label>
                            </div>
                        </div>

                        <div className="qr-form-row">
                            <div className="qr-form-group">
                                <label>Driver Name *</label>
                                <input
                                    type="text"
                                    value={formData.driverName}
                                    onChange={(e) => setFormData({ ...formData, driverName: e.target.value })}
                                    required
                                    placeholder="Enter driver name"
                                    disabled={formData.isDriver}
                                />
                            </div>
                            <div className="qr-form-group">
                                <label>Driver Email *</label>
                                <input
                                    type="email"
                                    value={formData.driverEmail}
                                    onChange={(e) => setFormData({ ...formData, driverEmail: e.target.value })}
                                    required
                                    placeholder="Enter driver email"
                                    disabled={formData.isDriver}
                                />
                            </div>
                            <div className="qr-form-group">
                                <label>Mobile Number *</label>
                                <input
                                    type="tel"
                                    value={formData.mobile}
                                    onChange={(e) => setFormData({ ...formData, mobile: e.target.value })}
                                    required
                                    placeholder="+44 7XXX XXX XXX"
                                    disabled={formData.isDriver}
                                />
                            </div>
                        </div>

                        {/* Vehicle and PO section */}
                        <div className="qr-form-row">
                            <div className="qr-form-group">
                                <label>Vehicle Type *</label>
                                <select
                                    value={formData.vehicleType}
                                    onChange={(e) => setFormData({ ...formData, vehicleType: e.target.value })}
                                    required
                                >
                                    <option value="">Select vehicle type...</option>
                                    {vehicleTypes.map(type => (
                                        <option key={type.id} value={type.id}>{type.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="qr-form-group">
                                <label>PO Number</label>
                                <input
                                    type="text"
                                    value={formData.poNumber}
                                    onChange={(e) => setFormData({ ...formData, poNumber: e.target.value })}
                                    placeholder="PO number if available"
                                />
                            </div>
                        </div>

                        {/* Updated dates and times section */}
                        <div className="qr-form-row">
                            <div className="qr-form-group">
                                <label>Hire From *</label>
                                <div className="flex gap-4 items-center">
                                    <input
                                        type="date"
                                        value={formData.hireFromDate}
                                        onChange={handleFromDateChange}
                                        min={getTodayString()}
                                        required
                                        className="p-2 border rounded"
                                    />
                                    <TimeSelector
                                        selectedHour={formData.hireFromHour}
                                        selectedMinute={formData.hireFromMinute}
                                        onHourChange={(value) => {
                                            setFormData({ ...formData, hireFromHour: value });
                                            setError(null);
                                        }}
                                        onMinuteChange={(value) => {
                                            setFormData({ ...formData, hireFromMinute: value });
                                            setError(null);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="qr-form-group">
                                <label>Hire To *</label>
                                <div className="flex gap-4 items-center">
                                    <input
                                        type="date"
                                        value={formData.hireToDate}
                                        onChange={handleToDateChange}
                                        min={getTodayString()}
                                        required
                                        className="p-2 border rounded"
                                    />
                                    <TimeSelector
                                        selectedHour={formData.hireToHour}
                                        selectedMinute={formData.hireToMinute}
                                        onHourChange={(value) => {
                                            setFormData({ ...formData, hireToHour: value });
                                            setError(null);
                                        }}
                                        onMinuteChange={(value) => {
                                            setFormData({ ...formData, hireToMinute: value });
                                            setError(null);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="qr-form-row">
                            <div className="qr-form-group">
                                <label>Logistics *</label>
                                <select
                                    value={formData.logistics}
                                    onChange={(e) => handleLogisticsChange(e.target.value)}
                                    required
                                >
                                    <option value="">Select logistics option...</option>
                                    <option value="Collect">Collect from SMC office</option>
                                    <option value="Delivery">Delivery</option>
                                </select>
                            </div>
                        </div>

                        {/* Delivery address fields integrated in the same section */}
                        {showDeliveryAddress && (
                            <>
                                <div className="qr-form-row">
                                    <div className="qr-form-group">
                                        <label>Select Existing Address</label>
                                        <select
                                            onChange={(e) => handleAddressSelection(e.target.value)}
                                        >
                                            <option value="">Select an existing address or enter new</option>
                                            {businessAddresses.map(addr => (
                                                <option key={addr.id} value={addr.id}>
                                                    {addr.addressLine1}, {addr.town}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                <div className="qr-form-row">
                                    <div className="qr-form-group">
                                        <label>Address Line 1 *</label>
                                        <input
                                            type="text"
                                            value={formData.addressLine1}
                                            onChange={(e) => setFormData({ ...formData, addressLine1: e.target.value })}
                                            required
                                            placeholder="Enter address line 1"
                                        />
                                    </div>
                                    <div className="qr-form-group">
                                        <label>Address Line 2</label>
                                        <input
                                            type="text"
                                            value={formData.addressLine2}
                                            onChange={(e) => setFormData({ ...formData, addressLine2: e.target.value })}
                                            placeholder="Enter address line 2"
                                        />
                                    </div>
                                </div>

                                <div className="qr-form-row">
                                    <div className="qr-form-group">
                                        <label>Town *</label>
                                        <input
                                            type="text"
                                            value={formData.town}
                                            onChange={(e) => setFormData({ ...formData, town: e.target.value })}
                                            required
                                            placeholder="Enter town"
                                        />
                                    </div>
                                    <div className="qr-form-group">
                                        <label>Post Code *</label>
                                        <input
                                            type="text"
                                            value={formData.postCode}
                                            onChange={(e) => setFormData({ ...formData, postCode: e.target.value })}
                                            required
                                            placeholder="Enter post code"
                                        />
                                    </div>
                                </div>
                            </>
                        )}
                    </div>

                    <div className="qr-form-section">
                        <div className="qr-form-group">
                            <label>Additional Notes</label>
                            <textarea
                                value={formData.notes}
                                onChange={(e) => setFormData({ ...formData, notes: e.target.value })}
                                rows={4}
                                placeholder="Enter additional notes or requirements"
                            />
                        </div>

                        <div className="qr-form-group">
                            <label>Purpose & Destination for Hire</label>
                            <textarea
                                value={formData.purpose}
                                onChange={(e) => setFormData({ ...formData, purpose: e.target.value })}
                                rows={4}
                                placeholder="Enter purpose and destination"
                            />
                        </div>
                    </div>

                    <div className="qr-form-actions">
                        <button type="submit" className="qr-submit-button">
                            Request Quote
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default QuoteRequest;