import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { ApiRepos } from '../../../contracts/ContractVars';
import './Sidebar.css';

const Sidebar = ({ toggleSidebar, opened }) => {
    const navigate = useNavigate();
    const [userLevel, setUserLevel] = useState(null);

    useEffect(() => {
        fetchUserLevel();
    }, []);

    const fetchUserLevel = async () => {
        try {
            const response = await fetch(`${ApiRepos}account/current-user`, {
                credentials: 'include'
            });
            if (response.ok) {
                const userData = await response.json();
                setUserLevel(userData.userLevel);
            }
        } catch (error) {
            console.error('Error fetching user level:', error);
        }
    };

    const handleLogout = async () => {
        try {
            const response = await fetch(`${ApiRepos}account/logout`, {
                method: 'GET',
                credentials: 'include'
            });
            if (response.ok) {
                navigate('/');
            } else {
                console.error('Logout failed');
            }
        } catch (error) {
            console.error('Error during logout:', error);
        }
    };

    const standardItems = [
        { title: 'Dashboard Home', path: 'home' },
        { title: 'Request a Quote', path: 'quote' },
        { title: 'View Quotes', path: 'quotes' },
        { title: 'Order Vehicle', path: 'order' },
        { title: 'View Orders', path: 'orders' },
        { title: 'Accident Support', path: 'accident-support' },
        { title: 'FAQs', path: 'faqs' },
        { title: 'My Profile', path: 'profile' },
        { title: 'Business Profile', path: 'business-profile' },
        { title: 'Manage Users', path: 'manage-users' },
    ];

    const adminItems = [
        { title: 'Quotes', path: 'quotes' },
        { title: 'Orders', path: 'orders' },
        { title: 'Accident Reports', path: 'accident-reports' },
        { title: 'Business Admin', path: 'business-profiles' },
        { title: 'Reporting', path: 'reporting' },
        { title: 'Settings', path: 'settings' },
    ];

    return (
        <>
            <div className={`sidebar-container ${opened ? 'active' : ''}`}>
                <div className="sidebar">
                    <ul className="sidebar-links">
                        {standardItems.map((item, i) => (
                            <li key={`standard-${i}`}>
                                <NavLink
                                    to={item.path}
                                    className={({ isActive }) =>
                                        `sidebar-button ${isActive ? 'active' : ''}`
                                    }
                                >
                                    {item.title}
                                </NavLink>
                            </li>
                        ))}

                        {userLevel === 9 && (
                            <>
                                <li className="admin-separator">
                                    <span className="text-sm font-semibold text-gray-400 px-4">
                                        Administrator Options
                                    </span>
                                </li>
                                {adminItems.map((item, i) => (
                                    <li key={`admin-${i}`}>
                                        <NavLink
                                            to={item.path}
                                            className={({ isActive }) =>
                                                `sidebar-button admin-button ${isActive ? 'active' : ''}`
                                            }
                                        >
                                            {item.title}
                                        </NavLink>
                                    </li>
                                ))}
                            </>
                        )}
                    </ul>
                    <button onClick={handleLogout} className="logout-button">
                        Logout
                    </button>
                </div>
            </div>
            <div
                className={`sidebar-overlay ${opened ? 'active' : ''}`}
                onClick={toggleSidebar}
            ></div>
        </>
    );
};

export default Sidebar;