import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ApiRepos } from '../../../contracts/ContractVars';

const ViewQuote = () => {
    const { id } = useParams();
    const [quote, setQuote] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchQuote = async () => {
            try {
                const response = await fetch(`${ApiRepos}order/${id}`, {
                    credentials: 'include'
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch quote details');
                }

                const data = await response.json();
                setQuote(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchQuote();
    }, [id]);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;
    if (!quote) return <div>Quote not found</div>;

    const formatDateTime = (date, hour, minute) => {
        const formattedDate = new Date(date).toLocaleDateString();
        return `${formattedDate} ${hour}:${minute}`;
    };

    return (
        <div className="p-6">
            <h2 className="text-2xl font-bold mb-6">Quote Request Details</h2>

            <div className="bg-white shadow-md rounded-lg p-6">
                <div className="grid grid-cols-2 gap-6">
                    <div>
                        <h3 className="font-semibold mb-4">Driver Information</h3>
                        <p><span className="font-medium">Name:</span> {quote.driverName}</p>
                        <p><span className="font-medium">Email:</span> {quote.driverEmail}</p>
                        <p><span className="font-medium">Mobile:</span> {quote.mobile}</p>
                    </div>

                    <div>
                        <h3 className="font-semibold mb-4">Hire Details</h3>
                        <p><span className="font-medium">From:</span> {formatDateTime(quote.hireFromDate, quote.hireFromHour, quote.hireFromMinute)}</p>
                        <p><span className="font-medium">To:</span> {formatDateTime(quote.hireToDate, quote.hireToHour, quote.hireToMinute)}</p>
                        <p><span className="font-medium">Purpose:</span> {quote.purpose}</p>
                    </div>

                    {quote.logistics === 'Delivery' && (
                        <div>
                            <h3 className="font-semibold mb-4">Delivery Address</h3>
                            <p>{quote.addressLine1}</p>
                            {quote.addressLine2 && <p>{quote.addressLine2}</p>}
                            <p>{quote.town}</p>
                            <p>{quote.postCode}</p>
                        </div>
                    )}

                    <div>
                        <h3 className="font-semibold mb-4">Additional Information</h3>
                        <p><span className="font-medium">PO Number:</span> {quote.poNumber || 'N/A'}</p>
                        <p><span className="font-medium">Notes:</span> {quote.notes || 'N/A'}</p>
                        <p><span className="font-medium">Status:</span> {quote.status === 0 ? 'Pending Quote' : 'Converted to Order'}</p>
                        {quote.quoteAmount && (
                            <p><span className="font-medium">Quote Amount:</span> �{quote.quoteAmount.toFixed(2)}</p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ViewQuote;