import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ApiRepos } from '../../../contracts/ContractVars';

const ViewOrder = () => {
    const { id } = useParams();
    const [order, setOrder] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchOrder = async () => {
            try {
                const response = await fetch(`${ApiRepos}order/${id}`, {
                    credentials: 'include'
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch order details');
                }

                const data = await response.json();
                setOrder(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchOrder();
    }, [id]);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;
    if (!order) return <div>Order not found</div>;

    const formatDateTime = (date, hour, minute) => {
        const formattedDate = new Date(date).toLocaleDateString();
        return `${formattedDate} ${hour}:${minute}`;
    };

    return (
        <div className="p-6">
            <h2 className="text-2xl font-bold mb-6">Order Details</h2>

            <div className="bg-white shadow-md rounded-lg p-6">
                <div className="grid grid-cols-2 gap-6">
                    <div>
                        <h3 className="font-semibold mb-4">Driver Information</h3>
                        <p><span className="font-medium">Name:</span> {order.driverName}</p>
                        <p><span className="font-medium">Email:</span> {order.driverEmail}</p>
                        <p><span className="font-medium">Mobile:</span> {order.mobile}</p>
                    </div>

                    <div>
                        <h3 className="font-semibold mb-4">Hire Details</h3>
                        <p><span className="font-medium">From:</span> {formatDateTime(order.hireFromDate, order.hireFromHour, order.hireFromMinute)}</p>
                        <p><span className="font-medium">To:</span> {formatDateTime(order.hireToDate, order.hireToHour, order.hireToMinute)}</p>
                        <p><span className="font-medium">Purpose:</span> {order.purpose}</p>
                    </div>

                    {order.logistics === 'Delivery' && (
                        <div>
                            <h3 className="font-semibold mb-4">Delivery Address</h3>
                            <p>{order.addressLine1}</p>
                            {order.addressLine2 && <p>{order.addressLine2}</p>}
                            <p>{order.town}</p>
                            <p>{order.postCode}</p>
                        </div>
                    )}

                    <div>
                        <h3 className="font-semibold mb-4">Order Information</h3>
                        <p><span className="font-medium">PO Number:</span> {order.poNumber || 'N/A'}</p>
                        <p><span className="font-medium">Notes:</span> {order.notes || 'N/A'}</p>
                        <p><span className="font-medium">Created By:</span> {order.requestedByUser}</p>
                        <p><span className="font-medium">Created Date:</span> {new Date(order.createdDate).toLocaleString()}</p>
                        {order.updatedByUser && (
                            <p><span className="font-medium">Last Updated By:</span> {order.updatedByUser}</p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ViewOrder;