import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApiRepos } from '../../../contracts/ContractVars';
import './BusinessProfiles.css';

const BusinessProfiles = ({ userData }) => {
    const [businesses, setBusinesses] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchBusinesses = async () => {
            try {
                const response = await fetch(`${ApiRepos}business/list`, {
                    credentials: 'include'
                });

                if (!response.ok) {
                    throw new Error('You do not have permission to view businesses');
                }

                const data = await response.json();
                setBusinesses(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchBusinesses();
    }, []);

    const handleHoldToggle = async (businessId, currentHoldStatus) => {
        try {
            const response = await fetch(`${ApiRepos}business/${businessId}/hold`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: 'include',
                body: JSON.stringify({ onHold: !currentHoldStatus })
            });

            if (!response.ok) {
                throw new Error('Failed to update business status');
            }

            // Update local state
            setBusinesses(businesses.map(business => {
                if (business.id === businessId) {
                    return { ...business, onHold: !business.onHold };
                }
                return business;
            }));
        } catch (err) {
            setError(err.message);
        }
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <div className="p-6">
            <div className="flex justify-between items-center mb-6">
                <h2 className="text-2xl font-bold">Business Admin</h2>
            </div>

            <div className="overflow-x-auto bg-white rounded-lg shadow">
                <table className="min-w-full">
                    <thead className="bg-gray-50">
                        <tr>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Business Name</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">VAT Number</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Website</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Status</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Registration Code</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Actions</th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                        {businesses.map(business => (
                            <tr key={business.id} className="hover:bg-gray-50">
                                <td className="px-6 py-4">{business.businessName}</td>
                                <td className="px-6 py-4">{business.vatNumber || 'N/A'}</td>
                                <td className="px-6 py-4">
                                    {business.website ? (
                                        <a
                                            href={business.website}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-blue-500 hover:underline"
                                        >
                                            {business.website}
                                        </a>
                                    ) : 'N/A'}
                                </td>
                                <td className="px-6 py-4">{business.onHold ? 'On Hold' : 'Active'}</td>
                                <td className="px-6 py-4">{business.registrationCode}</td>
                                <td className="px-6 py-4">
                                    <div className="flex gap-2">
                                        <button
                                            onClick={() => handleHoldToggle(business.id, business.onHold)}
                                            className="dashboard-button"
                                        >
                                            {business.onHold ? 'Resume' : 'Hold'}
                                        </button>
                                        <button
                                            onClick={() => navigate(`/dashboard/business/${business.id}`)}
                                            className="qr-submit-button2"
                                        >
                                            Edit
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default BusinessProfiles;