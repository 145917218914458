import React, { useState } from 'react';
import { ApiRepos } from '../../../contracts/ContractVars';

const BusinessAddress = ({ address, onAddressUpdated, onAddressDeleted }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editedAddress, setEditedAddress] = useState(address);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleEdit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);

        try {
            const response = await fetch(`${ApiRepos}business/address/${address.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify(editedAddress)
            });

            if (!response.ok) throw new Error('Failed to update address');

            const updatedAddress = await response.json();
            onAddressUpdated(updatedAddress);
            setIsEditing(false);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async () => {
        if (!window.confirm('Are you sure you want to delete this address?')) return;

        setLoading(true);
        setError(null);

        try {
            const response = await fetch(`${ApiRepos}business/address/${address.id}`, {
                method: 'DELETE',
                credentials: 'include'
            });

            if (!response.ok) throw new Error('Failed to delete address');

            onAddressDeleted(address.id);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    if (loading) return <div className="address-card loading">Loading...</div>;

    if (isEditing) {
        return (
            <div className="address-card editing">
                <form onSubmit={handleEdit}>
                    <div className="form-row">
                        <div className="form-group">
                            <label>Address Line 1 *</label>
                            <input
                                type="text"
                                value={editedAddress.addressLine1}
                                onChange={(e) => setEditedAddress({ ...editedAddress, addressLine1: e.target.value })}
                                required
                                maxLength={100}
                            />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group">
                            <label>Address Line 2</label>
                            <input
                                type="text"
                                value={editedAddress.addressLine2 || ''}
                                onChange={(e) => setEditedAddress({ ...editedAddress, addressLine2: e.target.value })}
                                maxLength={100}
                            />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group">
                            <label>Address Line 3</label>
                            <input
                                type="text"
                                value={editedAddress.addressLine3 || ''}
                                onChange={(e) => setEditedAddress({ ...editedAddress, addressLine3: e.target.value })}
                                maxLength={100}
                            />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group">
                            <label>Town *</label>
                            <input
                                type="text"
                                value={editedAddress.town}
                                onChange={(e) => setEditedAddress({ ...editedAddress, town: e.target.value })}
                                required
                                maxLength={100}
                            />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group">
                            <label>County</label>
                            <input
                                type="text"
                                value={editedAddress.county || ''}
                                onChange={(e) => setEditedAddress({ ...editedAddress, county: e.target.value })}
                                maxLength={100}
                            />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group">
                            <label>Post Code *</label>
                            <input
                                type="text"
                                value={editedAddress.postCode}
                                onChange={(e) => setEditedAddress({ ...editedAddress, postCode: e.target.value })}
                                required
                                maxLength={10}
                            />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group">
                            <label>Landline Number</label>
                            <input
                                type="tel"
                                value={editedAddress.landlineNumber || ''}
                                onChange={(e) => setEditedAddress({ ...editedAddress, landlineNumber: e.target.value })}
                                maxLength={20}
                            />
                        </div>
                    </div>

                    {error && <div className="error-message">{error}</div>}

                    <div className="form-actions">
                        <button type="submit" className="submit-button" disabled={loading}>
                            Save Changes
                        </button>
                        <button
                            type="button"
                            className="cancel-button"
                            onClick={() => setIsEditing(false)}
                            disabled={loading}
                        >
                            Cancel
                        </button>
                    </div>
                </form>
            </div>
        );
    }

    return (
        <div className="address-card">
            <p>{address.addressLine1}</p>
            {address.addressLine2 && <p>{address.addressLine2}</p>}
            {address.addressLine3 && <p>{address.addressLine3}</p>}
            <p>{address.town}</p>
            {address.county && <p>{address.county}</p>}
            <p>{address.postCode}</p>
            {address.landlineNumber && <p>Tel: {address.landlineNumber}</p>}

            {error && <div className="error-message">{error}</div>}

            <div className="card-actions">
                <button
                    onClick={() => setIsEditing(true)}
                    className="edit-button"
                    disabled={loading}
                >
                    Edit
                </button>
                <button
                    onClick={handleDelete}
                    className="delete-button"
                    disabled={loading}
                >
                    Delete
                </button>
            </div>
        </div>
    );
};

export default BusinessAddress;