import React, { useState } from 'react';

const Contact = () => {
    const [formData, setFormData] = useState({
        enquiryType: '',
        firstName: '',
        lastName: '',
        companyName: '',
        emailAddress: '',
        telephoneNumber: '',
        enquiry: ''
    });

    const enquiryTypes = [
        'Business hire enquiry',
        'Call Back Request',
        'Email Back Request',
        'General Enquiry',
        'Personal hire enquiry'
    ];

    const handleSubmit = (e) => {
        e.preventDefault();
        // TODO: Implement contact form submission
        console.log('Contact form submitted:', formData);
    };

    return (
        <div className="form-section contact-section">
            <h2>Contact Us</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>Type of Enquiry</label>
                    <select
                        value={formData.enquiryType}
                        onChange={(e) => setFormData({ ...formData, enquiryType: e.target.value })}
                        required
                    >
                        <option value="">Select...</option>
                        {enquiryTypes.map(type => (
                            <option key={type} value={type}>{type}</option>
                        ))}
                    </select>
                </div>
                <div className="form-row">
                    <div className="form-group half">
                        <label>First Name</label>
                        <input
                            type="text"
                            value={formData.firstName}
                            onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
                            required
                            placeholder="Enter your first name"
                        />
                    </div>
                    <div className="form-group half">
                        <label>Last Name</label>
                        <input
                            type="text"
                            value={formData.lastName}
                            onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
                            required
                            placeholder="Enter your last name"
                        />
                    </div>
                </div>
                <div className="form-group">
                    <label>Company Name</label>
                    <input
                        type="text"
                        value={formData.companyName}
                        onChange={(e) => setFormData({ ...formData, companyName: e.target.value })}
                        required
                        placeholder="Enter company name"
                    />
                </div>
                <div className="form-group">
                    <label>Email Address</label>
                    <input
                        type="email"
                        value={formData.emailAddress}
                        onChange={(e) => setFormData({ ...formData, emailAddress: e.target.value })}
                        required
                        placeholder="Enter your email address"
                    />
                </div>
                <div className="form-group">
                    <label>Telephone Number</label>
                    <input
                        type="tel"
                        value={formData.telephoneNumber}
                        onChange={(e) => setFormData({ ...formData, telephoneNumber: e.target.value })}
                        required
                        placeholder="Enter your telephone number"
                    />
                </div>
                <div className="form-group">
                    <label>Enquiry</label>
                    <textarea
                        value={formData.enquiry}
                        onChange={(e) => setFormData({ ...formData, enquiry: e.target.value })}
                        required
                        rows={4}
                        placeholder="Enter your enquiry details"
                    />
                </div>
                <button type="submit" className="submit-button explore-now">
                    Submit Enquiry
                </button>
            </form>
        </div>
    );
};

export default Contact;